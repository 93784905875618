<!--
 * @Description: 对接平台
 * @Author: ChenXueLin
 * @Date: 2021-10-18 17:36:58
 * @LastEditTime: 2021-12-30 17:00:18
 * @LastEditors: LiangYiNing
-->

<template>
  <div v-loading="loading" :element-loading-background="loadingBackground">
    <!-- 搜索头部 start -->
    <table-title :showSearch="false">
      <template v-slot:searchForm>
        <el-form class="search-list" ref="searchForm" :model="searchForm">
          <el-form-item class="search-item--2">
            <el-input
              v-model="searchForm.projectName"
              placeholder="对接项目信息"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
      </template>
    </table-title>
    <!-- 搜索头部 end -->
    <section class="table-wrapper">
      <el-table border height="600px" :data="tableData" highlight-current-row>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </section>
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { getAccessList } from "@/api";
export default {
  name: "installPhoto",
  mixins: [base, listPage, listPageReszie],
  data() {
    return {
      searchForm: {
        vehicleId: "",
        projectName: "", // 项目名称
        pageSize: 20,
        pageIndex: 1
      },
      total: 0,
      columnData: [
        {
          fieldName: "projectName",
          display: true,
          fieldLabel: "对接项目信息",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "applyDateStr",
          display: true,
          fieldLabel: "对接申请时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "statusName",
          display: true,
          fieldLabel: "对接状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "accessDateStr",
          display: true,
          fieldLabel: "对接成功时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //已安装设备表头
      tableData: [] // 表格数据
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {},
  methods: {
    init(vehicleId) {
      this.searchForm.vehicleId = vehicleId;
      if (this.searchForm.vehicleId) {
        this.handleSearch();
      }
    },
    async queryList() {
      try {
        this.loading = true;
        let res = await getAccessList(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.change-type-box {
  margin-top: 20px;
  height: 35px;
  display: flex;
  margin-left: 15px;
  box-sizing: border-box;
  .type {
    display: inline-block;
    text-align: center;
    line-height: 35px;
    color: #929395;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    border-top: 1px solid #dedfe1;
    border-bottom: 1px solid #dedfe1;
    border-right: 1px solid #dedfe1;
    &:nth-of-type(1) {
      border-left: 1px solid #dedfe1;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:nth-last-of-type(1) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .active {
    color: #fff;
    background: #46bfea;
    &:nth-of-type(1) {
      border-right: 1px solid #46bfea;
      border-left: 1px solid #46bfea;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:nth-last-of-type(1) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
</style>
